<template>
  <section id="Terminos" class="terminos">
    <v-container class="mx-auto terminos__wrapper">
      <v-row no-gutters>
        <!-- <v-col cols="1" class="d-none d-md-block">
          <FlechaAtras />
        </v-col> -->
        <v-col class="terminos__terminosYCondiciones">
          <v-container class="pa-10">
            <h1 class="mb-6 md-md-3">Términos y Condiciones HummingBird</h1>
            <div>
              <h3 class="titulo">1. General</h3>
              <p>
                1.1 HummingBird, titular intelectual y comercial del Sitio Web
                www.hummingbird.cl (en adelante, el “Sitio Web”), es una empresa
                que se dedica a gestionar la reserva y venta de vuelos
                compartidos y servicios turísticos para aquellos Usuarios que
                estén interesados en la contratación de estos Servicios.
                HummingBird pone a disposición de los Usuarios una plataforma
                www.hummingbird.cl a través de la cual, una persona física o
                jurídica (en adelante, el “Usuario”) podrá reservar y/o comprar
                experiencias de vuelo, visitas y traslados en distintos tipos de
                aeronaves, ejecutados por los proveedores de transporte aéreo en
                adelante “Operadores”.
              </p>

              <p>
                1.2 Cualquier persona que no acepte estos términos y condiciones
                generales y/o cualquiera de las demás políticas, términos y
                condiciones particulares y principios que rigen en HummingBird,
                deberán abstenerse de utilizar el sitio web y/o los Servicios.
                De igual forma, cualquier persona que utilice la plataforma,
                realice una compra y/o haga uso de los Servicios de HummingBird
                está aceptando implícitamente estos términos y condiciones.
              </p>

              <p>
                1.3 A continuación en el siguiente documento el Usuario podrá
                conocer los Términos y Condiciones al contratar Servicios con
                HummingBird.
              </p>

              <h3 class="titulo">2. Definiciones</h3>
              <p>
                2.1 <span class="boldItalic">HummingBird:</span> Es una
                Plataforma online que intermedia en la contratación de los
                Servicios de los Operadores aéreos de aeronaves livianas y otros
                proveedores de Servicios turísticos que operan con HummingBird.
              </p>

              <p>
                2.2 <span class="boldItalic">Operador:</span> Proveedor de
                Servicios aéreos o turísticos que se ofrecen en la plataforma y
                es el Transportador que ejecuta los Servicios contratados.
                Cumplen con certificado de AOC vigente otorgado por la DGAC.
              </p>
              <p>
                2.3 <span class="boldItalic">Usuario:</span> Persona física o
                jurídica que está interesada en la contratación de los Servicios
                que ofrece la plataforma online.
              </p>
              <p>
                2.4 <span class="boldItalic">Pasajero:</span> Persona
                beneficiaria del Servicio de transporte aéreo ejecutado por el
                Operador.
              </p>

              <p>
                2.5 <span class="boldItalic">Ticket Aéreo:</span> Es el billete
                de pasaje que hace fe de la celebración del contrato de
                transporte aéreo entre el Operador (transportador) y el Usuario
                (Pasajero) (Art. 131 Código Aeronáutico de Chile), y significa
                el conjunto de documentos que dan cuenta y hacen fe de la
                celebración y de las condiciones del contrato de transporte
                aéreo y control de equipaje, y que están comprendidos por todos
                o algunos de los siguientes documentos: (i) las presentes
                Condiciones del Contrato de Transporte Aéreo; (ii) las
                disposiciones contenidas en el documento denominado “Información
                de su Compra” entregado por la compañía o detalles de la reserva
                entregados por cualquier agente; (iii) el billete electrónico
                registrado magnéticamente; (iv) las condiciones específicas
                aplicables a la tarifa pagada por el Pasajero; (v) la Tarjeta de
                Embarque o Boarding Pass; y (vi) las comunicaciones y las
                tarifas de contratación que se encuentren registradas ante las
                autoridades aeronáuticas en los países que lo requieran.
              </p>

              <p>
                2.6
                <span class="boldItalic">Convención de Varsovia:</span>
                Significa el Convenio para la Unificación de ciertas reglas
                relativas al Transporte Aéreo Internacional de 1929 suscrito en
                Varsovia y las modificaciones introducidas por el Protocolo de
                La Haya de 1955 y los Protocolos de Montreal de 1975.
              </p>

              <p>
                2.7
                <span class="boldItalic">Convención de Montreal:</span>
                Significa el Convenio para la Unificación de ciertas reglas para
                el Transporte Aéreo Internacional firmado en Montreal, Canadá,
                el 18 de Mayo de 1999.
              </p>

              <p>
                2.8
                <span class="boldItalic">Condiciones de tarifa:</span>
                Condiciones de reserva para usar el ticket aéreo dado,
                incluidas, entre otras, las condiciones para devolver el ticket
                aéreo y el tamaño del equipaje.
              </p>

              <p>
                2.9 <span class="boldItalic">Servicio:</span> Se refiere al
                vuelo o experiencia contratada a través de HummingBird.
              </p>

              <p>
                2.10
                <span class="boldItalic">Contrato de transporte aéreo:</span>
                Contrato de transporte aéreo es aquél en virtud del cual el
                Operador se obliga, por cierto precio, a conducir de un lugar a
                otro, por vía aérea, pasajeros o cosas ajenas y a entregar éstas
                a quienes vayan consignadas. (Art. 126 Código Aeronáutico de
                Chile). Se define como transportador al Operador.
              </p>

              <p>
                2.11
                <span class="boldItalic">Política de Privacidad:</span> Política
                normativa que regula en materia del tratamiento de los datos
                personales de los Usuarios del sitio web de HummingBird, y de
                confidencialidad de datos proporcionados por los Usuarios y
                busca cumplir con la ley vigente en el Estado de Chile. Esta
                política se encuentra completa en el Sitio Web
                <a href="http://www.hummingbird.cl">www.hummingbird.cl</a>.
              </p>

              <h3 class="titulo">3. Condiciones de Compra</h3>

              <p>
                3.1 El Usuario debe ingresar los datos completos, exactos y
                reales en la solicitud de compra. Solo así se podrá mantener
                comunicación y cumplir con la normativa aeronáutica, para la
                seguridad del vuelo.
              </p>

              <p>
                3.2 El Usuario debe leer estos términos y condiciones y podrá
                hacer una mejor elección y ejercer en mejor forma sus derechos
                de consumidor.
              </p>

              <p>
                3.3 Se le sugiere al Usuario imprimir y conservar los “Términos
                y Condiciones” junto con su ticket aéreo.
              </p>

              <p>
                3.4 El Usuario tiene el deber de informarse sobre la
                documentación requerida para poder realizar su vuelo conforme a
                lo planeado. Debe verificar con tiempo si requiere pasaporte,
                visa, vacunas, permiso para menores u otras exigencias de
                documentos. Si no dispone de la documentación requerida, el
                Usuario debe realizar los trámites necesarios para obtenerla con
                anticipación. No se admitirá el embarque sin la documentación
                requerida.
              </p>

              <p>
                3.5 Los Servicios constituyen una plataforma tecnológica que
                permite a los Usuarios de HummingBird planificar y contratar,
                incluso a través del modo compartido o privado, Servicios de
                transporte y / o turismo prestados por Operadores.
              </p>

              <p>
                3.6 El Usuario reconoce que HummingBird no ejecuta Servicios de
                transporte aéreo. HummingBird será utilizado por los Usuarios
                para solicitar y planificar los Servicios de transporte aéreo
                incluso del modo de vuelos compartidos de los Operadores, sin
                embargo, el Usuario acepta y acepta expresamente que HummingBird
                no tiene ninguna responsabilidad y ni será responsable con el
                Usuario por cualquier equipo de vuelo utilizado para transporte.
                HummingBird declara que todos los Operadores Socios Aéreos y sus
                aeronaves cuentan con la documentación requerida por ley.
                HummingBird es una empresa de tecnología de la información, un
                sistema informático de reservas en línea con diferentes
                proveedores, es decir, una aplicación marketplace, que no
                proporciona productos o Servicios directos a los Usuarios, sólo
                conecta las partes y transmite información requerida entre el
                Usuario y el Operador.
              </p>

              <p>
                3.7 El Usuario debe estar presente 30 minutos antes de cada
                salida, bajo pena de cancelación de transporte o cargo total, si
                comparte el mismo Servicio con otras personas.
              </p>

              <p>
                3.8 El Servicio se realizará en la hora establecida. El Operador
                no puede esperar el retraso de la llegada de un pasajero. No
                presentarse a la hora señalada dará lugar a pérdida completa
                para el Usuario, sin derecho a devolución ni reembolso del
                Servicio.
              </p>

              <p>
                3.9 El Usuario debe notificar si transportan animales, lo cual
                quedará sujeto a la autorización del Operador de la aeronave,
                quien puede rechazar el transporte a su sola discreción.
              </p>

              <p>
                3.10 Cuando el Usuario contrata un Servicio a través de la
                plataforma, HummingBird, debe pagar el equivalente a la suma del
                precio o tarifa del Servicio turístico, más impuestos y tasas ,
                cuando correspondan. De este pago, recaudado por HummingBird, la
                plataforma solo retiene el valor correspondiente al Servicio de
                intermediación. El precio por el o los Servicios pertenece a los
                proveedores finales (Operadores). Es importante que antes de
                aceptar la transacción, preste mucha atención y lea
                cuidadosamente las condiciones y valores vigentes que le
                transmite HummingBird, antes de procesar su compra.
              </p>

              <p>
                3.11 Las tasas aeroportuarias y los impuestos que fueren
                aplicables son adicionales a las tarifas y Servicios publicadas
                y se incluyen en el valor final publicado durante el proceso de
                compra. Los gobiernos y/o las autoridades locales de los
                aeropuertos pueden imponer y recoger tasas o impuestos
                adicionales en el aeropuerto o aeródromos.
              </p>

              <p>
                3.12 Los medios de pago que el Usuario puede utilizar en la
                plataforma, son informados conjuntamente con los Servicios
                ofrecidos por los Operadores, siempre en forma previa a su
                solicitud de compra o reserva. Cuando el Usuario ingrese los
                datos de su cuenta o tarjeta y acepta la operación, con ello
                autoriza el cargo por precio y/o tarifa, y el pago del cargo por
                el Servicio contratado. Si el Usuario efectúa una compra en
                cuotas mediante una tarjeta de crédito, se debe tener presente
                que los intereses, impuestos, comisiones u otros cargos que
                pudieran generar las compras en cuotas mediante tarjetas de
                crédito, dependen de las condiciones que el consumidor haya
                pactado con cada banco o emisor de tarjeta. Se le recomienda al
                Usuario informarse de las condiciones de uso y de interés de su
                tarjeta de crédito antes de utilizar modalidad de compra en
                cuotas.
              </p>

              <p>
                3.13 El proceso inicia con la compra del Servicio realizada por
                el Usuario desde el sitio web. Enviada la confirmación de la
                compra en forma electrónica, con un código asociado al Ticket
                aéreo. El Usuario debe estar atento a las comunicaciones
                posteriores, ya que en éstas se le irá informando sobre los
                avances y estado del Servicio. La gestión de la reserva y/o la
                confirmación de la compra, dependen del pago íntegro y oportuno
                del precio y/o tarifa del Servicio.
              </p>

              <p>
                3.14 HummingBird le informa que en virtud de la norma del
                artículo 3 bis letra b) de la Ley 19.496 sobre Protección del
                Consumidor, ha dispuesto que en las compras a distancia
                efectuadas por el intermedio, no opera el derecho de retracto.
                En el caso de cancelaciones regirse por el punto 7 más adelante.
              </p>

              <h3 class="titulo">
                4. Condiciones del contrato del transporte aéreo
              </h3>

              <p>
                4.1 La emisión del Ticket aéreo da fe de la celebración y
                aceptación de las condiciones del transporte aéreo y del
                contrato de transporte suscrito entre el Usuario y el Operador
                cuyos nombres aparecen en el Ticket aéreo. El Ticket aéreo
                emitido a nombre del pasajero es nominativo, personal e
                intransferible y su emisión podrá realizarse total o
                parcialmente a través de medios físicos o electrónicos.
              </p>

              <p>
                4.2 Cualquier exención o limitación de responsabilidad de
                HummingBird y del Operador en virtud de la normativa local o
                internacional vigente, se aplicará e irá en beneficio de los
                agentes, empleados y representantes de HummingBird y del
                Operador y cualquier otra persona o empresa cuya aeronave
                utilice el Operador para efectuar el transporte y los agentes,
                empleados o representantes de dicha persona o empresa.
              </p>

              <p>
                4.3 HummingBird y el Operador se reservan la facultad
                irrestricta de denegar el transporte en cualquiera de los tramos
                del itinerario contratado por el pasajero, si la tarifa
                contratada, sus costos y/o gastos asociados, no se han pagado en
                todo o parte, si el medio de pago utilizado ha sido rechazado,
                revocado o dejado sin efecto, si se negara a cumplir con
                cualquiera de las políticas o condiciones informadas en el
                presente Contrato de Transporte Aéreo, o si se ha obtenido el
                Ticket aéreo con infracción a la ley. En dichos supuestos, el
                Usuario perderá su Ticket aéreo o rige el punto 7 más adelante
                en este documento
              </p>

              <p>
                4.4 De igual manera, el Operador negará el embarque de un
                pasajero, o dispondrá su desembarque, si estima que el pasajero
                pudiera afectar la seguridad del vuelo o de los pasajeros.
                Especialmente, se negará el embarque a cualquier pasajero que
                presente cualquier actitud o comportamiento que en tierra y/o a
                bordo de la aeronave constituya (i) un acto contrario a las
                instrucciones impartidas por la tripulación de la aeronave o de
                cualquiera de los empleados del Operador; y/o (ii) una conducta
                contraria al comportamiento razonable que debe mantener una
                persona o pasajero; y/o (iii) una infracción o delito que a
                criterio del Operador puedan poner en peligro o riesgo la
                seguridad de la aeronave o de las personas o bienes en la misma,
                o que ponga en peligro o comprometa el buen orden y disciplina a
                bordo; y/o (iv) en general, cualquier actitud o comportamiento
                que a criterio del Operador constituya una negación al
                cumplimiento de instrucciones impartidas por la tripulación y/o
                actitudes que pudieran poner en peligro o someter a algún riesgo
                la operación y/o que perturben el orden y/o la disciplina; y/o
                (v) una muestra o signos de haber ingerido bebidas alcohólicas
                en exceso y/o alguna sustancia psicotrópicas; y/o (vi) el
                incumplimiento de cualquier ley o regulación que aplique o que
                no acate cualquier requerimiento realizado por la autoridad
                gubernamental respectiva.
              </p>

              <p>
                4.5 En caso de necesidad (incluyendo, pero no limitado, a caso
                fortuito o fuerza mayor, tales como fenómenos meteorológicos,
                mantenimiento no programado de la aeronave, conflictos armados,
                disturbios civiles, o amenazas contra la aeronave), el Operador,
                puede cancelar la operación, hacerse sustituir por otros
                Operadores y/o cambiar la aeronave destinada a la operación y/o
                alterar u omitir paradas indicadas en el Ticket aéreo si fuere
                necesario. Hummingbird garantiza la coordinación de los cambios,
                para la correcta realización de los Servicios contratados y/o
                las compensaciones o devoluciones necesarias, si aplican.
              </p>

              <p>
                4.6 Todo pasajero que no se presente a viajar o que llegue
                posterior a la hora planificada para su embarque y/o chequeo en
                el vuelo correspondiente (check-in) o que afecte la seguridad
                del vuelo, con arreglo al numeral 4.4 precedente, perderá su
                Ticket aéreo. Cualquier cambio de fecha, origen, destino,
                itinerario del Ticket aéreo, así como la devolución del valor
                pagado por el mismo, queda sujeto a las condiciones y
                restricciones de la tarifa contratada por el pasajero. Con todo,
                en caso que el pasajero no efectúe el viaje y aunque su tarifa
                no permita devolución del Ticket aéreo, podrá solicitar al
                Operador la devolución de aquellas tasas aeroportuarias que son
                reembolsables de acuerdo a las normas o limitaciones del
                respectivo país. En caso que dichas tasas no hayan sido
                incluidas en el valor pagado (i.e. fueron recaudadas
                directamente por el aeropuerto), el pasajero deberá solicitar su
                reembolso directamente ante la autoridad aeroportuaria
                respectiva, sujeto a las limitaciones o normas antes indicadas.
              </p>

              <p>
                4.7 Los vuelos o tramos que componen el itinerario deberán
                volarse en el orden consecutivo indicado. El Operador, sujeto a
                las condiciones previstas en la legislación aplicable, podrá
                negar el embarque a un pasajero que no cumpla con el orden del
                itinerario antes referido o si el pasajero no ha volado alguno
                de los tramos indicados en su Ticket aéreo. El pasajero entiende
                que si no se vuela alguno de los tramos indicados en el
                itinerario, éste se cancelará en su totalidad, sin previo aviso,
                no procediendo ningún tipo de reembolso salvo que las
                condiciones de la tarifa de su Ticket aéreo lo permitan. A modo
                de ejemplo y sin que esta condición se limite a este caso en
                específico, si el pasajero no vuela el primer tramo que se
                especifica en el itinerario (entendiéndose como ida), este no
                podrá volar ningún otro (entendiéndose como vuelo de escala o de
                vuelta).
              </p>

              <p>
                4.8 Se encuentra estrictamente prohibido fumar dentro de la
                cabina de los aviones o dentro de los helicópteros del Operador.
                El Operador se reserva el derecho de negar el transporte a
                cualquier pasajero que no esté dispuesta a aceptar esta
                condición.
              </p>

              <p>
                4.9 El Ticket aéreo es válido por un año desde la fecha de su
                emisión en el caso que no tenga fecha de vuelo o válido hasta la
                fecha que se indique específicamente para la tarifa adquirida
                por el pasajero. Transcurrido dicho plazo, o cualquier otro
                plazo inferior indicado en las condiciones de la tarifa, el
                Ticket aéreo no podrá ser utilizado.
              </p>

              <p>
                4.10 Es de exclusiva responsabilidad del pasajero informarse,
                obtener y cumplir con los requisitos para viajar que impone
                cualquier autoridad del lugar de origen y de destino y deberá
                presentar los documentos de identificación, de salida, tránsito,
                entrada, visas y demás exigidos dependiendo del lugar de
                destino, sin caberle al Operador responsabilidad alguna por los
                atrasos o negativas de embarque que sufra el pasajero asociados
                o derivados del incumplimiento por parte de éste último de la
                obligación anterior. El Operador negará el embarque a aquellos
                pasajeros que no presenten la documentación necesaria o cuya
                identidad no corresponda con la indicada en la Tarjeta de
                Embarque o Boarding Pass, sin ulterior responsabilidad para el
                Operador.
              </p>

              <p>
                4.11 El pasajero deberá presentarse en la dirección y hora
                indicadas por HummingBird para el lugar del embarque, y si no se
                hubiera fijado alguna hora, con suficiente anticipación para
                cumplir con los trámites de salida, la que no podrá ser inferior
                a 30 minutos de antelación a la hora fijada para la salida del
                vuelo.
              </p>

              <p>
                4.12 En el caso de que en un vuelo existan más pasajeros con
                reserva confirmada que espacios disponibles, HummingBird pedirá
                voluntarios para que cedan su cupo confirmado a cambio de una
                indemnización pactada. Si no se encontraran suficientes
                voluntarios y se tuviera que denegar el embarque a pasajeros
                contra su voluntad, estos pasajeros tendrán derecho a las
                prestaciones que establezca la legislación aplicable. Rige punto
                7 más adelante.
              </p>

              <p>
                4.13 El Operador se compromete a realizar todas las acciones que
                le competen para transportar al pasajero y su equipaje con
                razonable puntualidad. Las horas que se indican en el Ticket
                aéreo de Pasaje o en alguna otra publicación no están
                garantizadas en cuanto a su cumplimiento ya que pueden variar
                por motivos de fuerza mayor sobreviniente, seguridad u otras
                circunstancias extraordinarias, como fenómenos meteorológicos,
                conflictos armados, disturbios civiles o amenazas contra la
                aeronave. El Operador adoptará todas las medidas razonables y
                que sean posibles para evitar demoras en el transporte de sus
                pasajeros y equipajes. (Art. 127 Código Aeronáutico de Chile).
              </p>

              <p>
                4.14 Es obligación del Pasajero indicar si requiere asistencia
                especial (por ejemplo, silla de ruedas, camilla, oxígeno,
                transporte con un animal de asistencia o soporte emocional,
                entre otros), con la debida antelación o al momento de la compra
                del pasaje. El Operador no será responsable ante el Pasajero si
                no solicitó la asistencia con antelación. Los Pasajeros deben
                informarse con la antelación necesaria al viaje, acerca de
                ciertos requisitos o condiciones especiales del transporte que
                deberán cumplirse y coordinarse antes de la aceptación de dichos
                pasajeros para el embarque (por ejemplo, transporte de un menor
                de edad no acompañado, infante, etc.), liberando desde ya al
                Operador de cualquier responsabilidad en caso que éste negare el
                embarque por no haber cumplido con las normas y/o políticas de
                la compañía vigentes sobre el particular.
              </p>

              <p>
                4.15 Es obligación del Pasajero cumplir con todas las medidas
                sanitarias, de seguridad, higiene y protección de salud pública
                impuestas por los Gobiernos de cada país, así como los indicados
                por HummingBird y por el Operador, y cuyo objeto es cumplir con
                la medidas impuestas por la autoridad sanitaria.
              </p>

              <p>4.16 En el caso de que el Usuario sea una mujer Embarazada:
              <ul>
                <li>
                  Encontrándose con menos de 28 semanas de embarazo, sin
                  complicaciones y buena salud, podrá viajar sin necesidad de
                  presentar certificado médico. Sin embargo, si tiene
                  complicaciones en el embarazo, es de alto riesgo, o tiene
                  problemas de salud, deberá presentar un certificado médico en
                  los términos indicados en el párrafo siguiente.
                </li>

                <li>
                  En caso de tener 28 o más semanas de embarazo, para poder ser
                  embarcada, deberá presentar a la compañía un certificado
                  médico que la autorice a viajar. Dicho certificado debe
                  contener la siguiente información mínima:

                  <ul>
                    <li>Itinerario con fecha(s) y hora(s) de partida.</li>
                    <li>Semanas de gestación.</li>
                    <li>
                      Autorización expresa para realizar el viaje del médico
                      tratante con timbre y firma.
                    </li>
                    <li>
                      El certificado médico debe tener una fecha de emisión no
                      anterior a los 10 días previos a la salida del primer
                      vuelo y debe ser presentado a la compañía a más tardar 1
                      hora antes de la salida del vuelo.
                    </li>
                  </ul>
                </li>

                <li>
                  No podrá ser embarcada en caso de encontrarse dentro de los 7
                  días anteriores o los 7 días posteriores a la fecha estimada
                  del parto.
                </li>

                <li>
                  Las regulaciones antes indicadas aplican tanto para embarazos
                  normales como para embarazos múltiples.
                </li>
              </ul>
</p>
              <h3 class="titulo">
                5. Condiciones para el transporte del equipaje
              </h3>

              <p>
                5.1 El Usuario seleccionará el tipo de aeronave dado y cumplirá
                con las condiciones de equipaje establecidas por el Operador. No
                se aceptarán exceso de equipaje ni cargos adicionales por exceso
                de peso. No se transportará exceso de equipaje por razones de
                seguridad.
              </p>

              <p>
                5.2 En cumplimiento de normas internacionales y locales, el
                Operador negará el transporte como equipaje (de mano o cabina),
                de aquellos artículos y sustancias peligrosas que pueden
                constituir un riesgo importante para la salud, la seguridad del
                vuelo o la propiedad, tales como:
                <ul>
                  <li>Gases (comprimidos, licuados, en solución o intensamente refrigerados), incluidos los aerosoles, que sean inflamables, tóxicos o inocuos tales como, butano, oxígeno, nitrógeno líquido, aerosoles conteniendo gases paralizantes, tubos de relleno para encendedores de gas licuado, etc.</li>

                  <li>Corrosivos, sólidos o líquidos, tales como: ácidos, alcalíes, mercurio, baterías de electrólito líquido, etc.</li>

                  <li>Explosivos tales como municiones, fuegos artificiales y bengalas, maletines con dispositivos de alarma, fulminantes para pistolas de juguetes, etc.</li>

                  <li>Líquidos inflamables tales como combustibles, pinturas, diluyentes, etc.</li>

                  <li>Materiales radiactivos, cualquiera que sea su categoría.</li>

                  <li>Materiales oxidantes y peróxidos orgánicos, tales como: blanqueadores, abonos, etc.</li>

                  <li>Sustancias tóxicas e infecciosas tales como: insecticidas, plaguicidas, productos biológicos que contengan gérmenes patógenos, etc.</li>

                  <li>Sólidos inflamables tales como cerillas(fósforos)</li>

                  <li>Armas, entendiéndose por tales a todo elemento u objeto que esté hecho, o pueda ser utilizado, para el ataque o defensa, tales como: armas de fuego, armas blancas, gases, elementos de choque eléctrico, punzantes, con filo, contundentes, entre las que se pueden incluir porras, hachas y bastones o palos con un peso en su interior o en forma de espigón.</li>

                </ul>
              </p>

              <p>5.3 Con el objeto de velar por la seguridad del vuelo y cumplir con la normativa vigente, el Operador y el personal de seguridad del aeropuerto, podrán hacer retiro, de los artículos y sustancias peligrosas transportados por el Pasajero en su equipaje de mano o de cabina, con el objeto de ponerlos a disposición de las autoridades respectivas de acuerdo a las normativas locales aplicables al respecto.</p>

              <p>5.4 Existe una cantidad limitada de artículos medicinales y de tocador que les está permitido transportar a los pasajeros. Antes de incluirlos en su equipaje el pasajero deberá informarse con el personal del Operador.</p>

              <p>5.5 Algunos de los artículos mencionados anteriormente, pueden ser transportados por vía aérea bajo ciertas condiciones especiales, amparado por un conocimiento de embarque y bajo una tarifa de carga aérea, para lo cual el pasajero deberá consultar previamente al Operador.</p>

              <p>5.6 Se entenderá por equipaje, solamente los efectos personales necesarios para efectuar el viaje. </p>

              <p>5.7 El pasajero no podrá enviar el equipaje a transportar en ningún otro vuelo que no sea en el que vaya a abordar.</p>

              <p>5.8 El Operador no registrará el equipaje a un destino que no sea el final que figura en el Ticket del pasajero.</p>

              <p>5.9 El pasajero deberá ajustarse al peso, medidas y máximo de equipaje permitido que se indican en www.hummingbird.cl y en el documento Información de su Compra referido en el número 2.5 anterior. La franquicia de equipaje y los pagos o recargos a que quedará afecto el exceso de equipaje deberán consultarse directamente con el Operador y en www.humminbird.cl. Los Operadores  se reservan el derecho de realizar los cobros por concepto de equipaje (por ejemplo, equipaje adicional al permitido, exceso de peso y dimensiones, entre otros). En caso que el pasajero no realice dicho pago, el equipaje no será embarcado en el vuelo ni el Operador asumirá la custodia del mismo.</p>

              <p>5.10 El equipaje permitido, puede variar según condiciones de la tarifa, cabina y ruta, y estará expresado en piezas o kilos. El Operador se reserva el derecho de alterar dichas franquicias y las dimensiones del equipaje. Las condiciones y las tarifas de contratación serán registradas ante las autoridades aeronáuticas locales correspondientes en la medida que así se exija.</p>

              <p>5.11 Como precaución y seguridad, las piezas de equipaje que se permitan llevar en cabina deberán tener las dimensiones y peso que permitan ubicarlos en los compartimentos del avión o helicóptero o bajo los asientos.   El pasajero deberá ajustarse al peso, medidas y máximo de equipaje de mano permitido que se indican en www.hummingbird.cl y en el documento Información de su Compra referido en el número 2.5 anterior.  
</p>

              <p>5.12 El equipaje que sea llevado en un compartimento externo del avión o helicóptero será entregado al portador del talón de equipaje. En caso de daño al equipaje durante el transporte o de retraso en su entrega, deberá presentarse el reclamo por escrito inmediatamente después de descubrirse el daño o en el tiempo que indique la normativa aplicable. Para los efectos del párrafo anterior, se entenderá por "retraso", toda demora que se provoque en la recepción del equipaje y que se haya producido por responsabilidad directa del Operador.</p>

        <h3 class="titulo">6. Límites de Responsabilidad</h3>
        <p>6.1 HummingBird garantiza que los Operadores aéreos de aeronaves livianas tienen todas las licencias, permisos, autorizaciones o aprobaciones y registros requeridos por la Autoridad aeronáutica (DGAC) o cualquier otra autoridad reguladora federal, estatal u otra requerida para la ejecución de este contrato, incluido sus certificados AOC actuales y válidos, y deben cumplir con todos los requisitos y leyes necesarios para mantener sus certificados.</p>

        <p>6.2 El Operador será totalmente responsable de la aeronave en todo momento y mantendrá su control operativo durante el rodaje, el despegue, el aterrizaje y la finalización de la operación, y puede finalizar el vuelo o negarse a iniciarlo sin ninguna responsabilidad incidental o consecuente, por pérdida, lesión, daño o retraso causado por dicha terminación o rechazo, si en su mejor opinión, la operación se está poniendo en riesgo.</p>

        <p>6.3 Cada aeronave, independientemente de su categoría operativa, debe tener cobertura de seguro de responsabilidad civil, correspondiente a su categoría de registro. En consecuencia, todos los Operadores Aéreos socios de HummingBird están certificados por la autoridad aeronáutica y, por lo tanto, brindan un seguro obligatorio de responsabilidad civil a los Usuarios. Como HummingBird no tiene relación con este Servicio, la responsabilidad recae completamente en el Operador aéreo. </p>

        <p>6.4 Si el viaje de un pasajero se inicia y termina dentro de un mismo país, el Servicio de transporte y los límites de responsabilidad estarán regulados por la legislación aplicable de dicho país. En caso que el viaje de un pasajero tenga el carácter de internacional, serán aplicables la Convención de Varsovia o la Convención de Montreal o cualquier otra normativa internacional aplicable, según corresponda. Estas convenciones y normativas regularán, cuando corresponda, los límites de responsabilidad del Operador por muerte o lesiones personales del pasajero y la pérdida, retrasos o cancelaciones de vuelo, retraso o daño del equipaje.
</p>

        <p>6.5 Respecto de los artículos de alto valor comercial y artículos electrónicos, el Usuario deberá transportar estos objetos como equipaje de mano, con el objeto de mantener siempre la custodia de los mismos, ya que el Operador no responderá por éstos más allá de los límites establecidos en la ley o convenciones internacionales aplicables en caso de ser transportados como equipaje de cabina.</p>

        <p>6.6 La responsabilidad del Operador en caso de muerte o lesiones corporales del pasajero y/o por la pérdida, retraso o daño al equipaje en caso de transporte dentro de dos puntos de un mismo país, está limitada por las leyes y legislación aplicable del país correspondiente. Asimismo, la responsabilidad del Operador en caso de muerte o lesiones corporales del pasajero y/o por la pérdida, retraso o daño al equipaje en vuelos internacionales, está limitada por los convenios internacionales relativos al transporte aéreo internacional, ya sea por el Convención de Varsovia, como por la Convención de Montreal  según fuere aplicable.</p>

        <h3 class="titulo">7. Cambios, cancelaciones y compensaciones</h3>

        <p>7.1 HummingBird establece que las condiciones de cambios y devoluciones serán de acuerdo a la tarifa cancelada, porque habrán diferentes tipos de condiciones según sea el caso del ticket aéreo, sin embargo, todas las tarifas permiten cambios de fecha, vuelo, ruta y nombre, sujeto al pago de multas y/o diferencia tarifaria que corresponda.</p>
        <p>7.2 Los cambios son por pasajero y por tramo y pueden ser realizados hasta 48 horas  antes de la hora de salida del vuelo a través de los canales de contacto que se encuentran publicados en <a href="http://www.hummingbird.cl">www.hummingbird.cl</a>. Cambios en el aeropuerto o aeródromo  no serán permitidos y tampoco cambios posteriores al vuelo.</p>
        <p>7.3 Para cambios de fecha, Servicio o ruta se deberá pagar una multa del 30% de la tarifa del ticket aéreo, para vuelos efectuados dentro del territorio nacional de la República de Chile.</p>
        <p>7.4 Para cambios de nombre una multa de $25.000 CLP para vuelos efectuados dentro del territorio nacional de la República de Chile.</p>
        <p>7.5 Si el cambio es por un servicio de menor valor no hay devolución de diferencias producidas entre las tarifas de los tickets aéreos.</p>
        <p>7.6 Los tickets aéreos no tienen derecho a devolución ni reembolso. En caso de no realizar el viaje por causa imputable al pasajero, solo se podrá solicitar la devolución de la totalidad de las tasas de embarque e impuestos de conformidad a la ley, las cuales serán restituidas a su solo requerimiento del pasajero a través de los canales de contacto que se encuentran publicados en <a href="http://www.hummingbird.cl">www.hummingbird.cl</a>. </p>
        <p>7.7 En el caso de cancelaciones por parte del Operador, aplican el punto 4.3, 4.4, 4.5 y 4.6 anterior. </p>
        <p>7.8 Las compensaciones serán analizadas caso a caso por ser específicos los casos de cada Usuario. Sin embargo, HummingBird emitirá un documento que reembolsará el valor a compensar y que serán utilizables en la compra de otro Servicio con HummingBird. </p>

<h3 class="titulo">8. Cláusulas de confidencialidad de los datos de los Usuarios</h3>

<p>8.1 HummingBird está comprometido con la transparencia, seguridad, y el estricto cumplimiento normativo en materia de privacidad y protección de datos personales de los Usuarios. Para los efectos de estos Términos y Condiciones parte de la Política de Privacidad aparecen en este documento, sin embargo el Usuario debe revisar la Política de privacidad completa en el sitio web <a href="http://www.hummingbird.cl">www.hummingbird.cl</a>.</p>

<p>8.2 La información que obtiene HummingBird es suministrada de manera voluntaria por sus Usuarios, quienes aceptan y autorizan expresamente a HummingBird su utilización conforme a la Política de Privacidad y a la legislación aplicable.</p>

<p>8.3 El Usuario acepta en forma voluntaria y expresamente el tratamiento de sus datos personales de acuerdo con esta Política de Privacidad. Consecuentemente, la información personal del Usuario podrá ser procesada y almacenada, tanto en Chile, como en otros países. Sin perjuicio de lo anterior, su información personal no podrá revelarse para fines que no estén permitidos por la legislación aplicable.</p>

<p>8.4 HummingBird se encuentra facultada para recopilar toda la información personal proporcionada para estos fines y autorizada por el Usuario a través de su Sitio Web y/o proveniente de otras fuentes, pudiendo recolectar, almacenar, usar, circular, suprimir, transmitir y/o transferir sus datos personales.</p>

<p>8.5 HummingBird recibe y almacena automáticamente la información que los Usuarios proporcionen al navegar por el Sitio Web, al utilizar los Servicios en línea, ya sea al ingresar en él, solicitar o comprar productos, participar en promociones y ofertas, registrarse como Usuario en el Sitio Web y/o vía correo electrónico, entre otros. Lo anterior incluye toda la información publicada en foros, encuestas, grupos de chat o comentarios mediante los cuales el Usuario interactúe en el Sitio Web. Asimismo, incluye cualquier otra forma en que el Usuario se relacione con HummingBird o en virtud de la cual contrate algún Servicio, o cualquier información entregada por el Usuario a HummingBird mediante otros canales, tales como aplicaciones móviles asociadas al Sitio Web, redes sociales, centro de contacto (Contact Center), durante el proceso de Check-In o facturación de equipaje, sea que se lleve a cabo en el aeropuerto o aeródromo o en forma virtual o remota, a través de su participación en encuestas, envío de correos electrónicos, solicitudes, entre otros. </p>

<p>8.6 La información recopilada de conformidad al párrafo anterior, sin que la siguiente enumeración sea taxativa o limitativa en forma alguna, incluye o puede incluir el nombre completo de los Usuarios, dirección postal y/o domicilio, estado civil, ocupación, nacionalidad, género, número telefónico, cédula o tarjeta de identidad y/o número de pasaporte, dirección de correo electrónico, dirección IP y demás información de carácter informática o computacional, fotografías, videos y material multimedia o audiovisual, opiniones, preferencias, información de destino, antecedentes comerciales, antecedentes de medios de pago tales como tarjetas de crédito y/o de débito, y otros datos personales, así como toda clase de información relativa al perfil de cada Usuario e inicio de sesión utilizando los datos de su registro en otras plataformas.</p>

<p>8.7 HummingBird se encuentra autorizada a recoger la dirección de correo electrónico del Usuario y utilizar y mostrar su nombre completo y dirección de correo electrónico para efectos de comunicarse con el Usuario, para fines tales como notificaciones y la entrega de información relacionada con los Servicios ofrecidos por HummingBird. El Usuario podrá solicitar en todo momento no recibir más comunicaciones por medio del enlace (link) disponibilizado para cancelar suscripción, que está integrado en el pie de página de todos los correos electrónicos enviados por HummingBird, donde haciendo click en él, dejará de recibir futuros mensajes.</p>

<p>8.8 En general, la información personal entregada por el Usuario, será destinada a proporcionar el Servicio descrito en el Sitio Web, y para mejorar la experiencia del Usuario, adaptando mejor sus características y rendimiento, y para ofrecer información adicional, tales como promociones de socios y anunciantes. Igualmente, su información será tratada para: (i) reserva y Chek-in de Ticket Aéreo y Servicios opcionales, (ii) modificaciones en el contrato, (iii) cancelaciones y cambios de itinerario, (iv) reembolsos, (v) atención de consultas, quejas y reclamos, (vi) afiliación al club de descuentos u otros programas de fidelización, (vii) registros contables.</p>

<p>8.9 Los  datos personales podrán ser tratados por terceros que sean proveedores de HummingBird y que le presten servicios, bajo estrictas condiciones de confidencialidad y resguardo, para proporcionar algunos de los Servicios y características disponibles en el Sitio Web, y para permitir una comunicación fluida con los Usuarios; estos terceros podrán incluir, entre otros, centros de contacto (call centers), los representantes, agentes o intermediarios del Operador, en el caso que hubiese. </p>

<p>8.10 Cualquier información de carácter personal obtenida por HummingBird por los medios aquí descritos será tratada de conformidad con las disposiciones de estos Términos y Condiciones, también con las disposiciones de la Política de Privacidad y de acuerdo a la legislación vigente. </p>

<p>8.11 La información personal que obtenga HummingBird se mantendrá en sus bases de datos durante no más tiempo del necesario para los fines que dicha información fue recopilada. En este sentido, la información personal será eliminada por HummingBird cuando haya dejado de ser necesaria o pertinente para la finalidad con que fue obtenida, y haya expirado todo plazo de almacenamiento de información que fuere exigido legalmente o necesario para dar cumplimiento a la ley, y sin perjuicio de las facultades de sus titulares en esta materia, según se expondrá más adelante.</p>

<p>8.12 HummingBird podrá divulgar la información personal de sus Usuarios cuando esto sea necesario
para cumplir con la reglamentación que se le aplica en los países en donde opera, un mandato legal o judicial impuesto a HummingBird, incluyendo la protección y defensa de sus derechos, o de terceros.
</p>

<p>8.13 Los Usuarios titulares de sus datos personales tendrán los siguientes derechos: derecho a conocer, actualizar y rectificar sus datos personales, a solicitar prueba de la autorización otorgada al responsable del tratamiento, a ser informados respecto del uso que se le ha dado a sus datos personales, a presentar quejas ante las autoridades competentes por infracciones a la regulación sobre protección de datos personales y a revocar su autorización y/o solicitar la supresión de sus datos.</p>

<p>8.14 Los titulares de la información pueden ejercer sus derechos a conocer, actualizar, rectificar y suprimir información y revocar la autorización, mediante una solicitud escrita a HummingBird dirigida al correo electrónico contacto@HummingBird.cl. Las solicitudes de los Usuarios titulares de la información personal que se efectúen en función de lo dispuesto en el presente párrafo serán resueltas a la brevedad por HummingBird y, en cualquier caso, dentro de los plazos que establezca la ley para ello.</p>

<p>8.15 Por su parte, los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de la información personal facilitada y se comprometen a mantenerla debidamente actualizada, sin perjuicio de la facultad de HummingBird de actualizarla de oficio. HummingBird no responderá en forma alguna de los perjuicios y/o reclamos directos o indirectos que puedan surgir de información personal provista por sus Usuarios y/o titulares en forma errónea, inexacta, equívoca o incompleta. </p>

<p>8.16 El Usuario tiene prohibido proveer material o información ilegal, amenazante, difamatoria, pornográfica, obscena o cualquier otro contenido que pueda significar algún riesgo y/o perjuicio civil o penal.</p>

<p>8.17 Las leyes aplicables al presente documento serán las correspondientes al territorio jurisdiccional relevante, y en su defecto, las leyes del Estado de Chile.</p>

<p>8.18 HummingBird se reserva el derecho a modificar estos Términos y Condiciones y Política de Privacidad, de tiempo en tiempo, según lo considere oportuno o para adecuarse a nuevas reglamentaciones, y, se compromete a comunicar cualquier cambio a los Usuarios y titulares de la información personal en forma oportuna y clara, de conformidad con la ley aplicable.</p>

<p>8.19 El uso continuado del Sitio Web significará su aceptación de cualquier ajuste a estos Términos y Condiciones y a la Política de Privacidad del Sitio Web.</p>

<p>8.20 HummingBird ha adoptado niveles de seguridad de protección de la información personal de conformidad con la ley aplicable y ha instalado todos los medios y medidas técnicas razonables a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de la información personal entregada por los Usuarios. Sin perjuicio de ello, los Usuarios son conscientes de que tales medidas de seguridad no son inexpugnables y, en consecuencia, liberan a HummingBird de cualquier perjuicio que pudiera surgir de estas circunstancias y que no sea imputable directamente a HummingBird, de acuerdo a la legislación vigente. Con todo, ante cualquier incidente de seguridad que afecte la información personal, HummingBird se compromete a tomar las medidas que fueren razonables y exigidas por la ley para informar a los titulares y a la autoridad competente por los medios más expeditos posibles, y a tomar todas las medidas que sean razonablemente aplicables para reducir los daños que puedan derivarse de esta circunstancia.</p>

<p>8.21 HummingBird se obliga a respetar lo dispuesto en el Artículo 19 N° 4 de la Constitución Política de la República de Chile y la Ley N° 19.628 de la República de Chile, sobre Protección de Datos Personales. </p>

            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <!-- <router-link :to="{ name: 'Pago' }">
      <Boton
        texto="Aceptar y continuar"
        width="100%"
        height="92px"
        borderRadius="0"
        class="terminos__btnBottom d-md-none"
      />
    </router-link> -->
  </section>
</template>

<script>
import FlechaAtras from "@/components/FlechaAtras";
import CardDetalleConPrecioFinal from "@/components/Cards/CardDetalleConPrecioFinal";
import Boton from "@/components/Botones/Boton";
import { mapState } from "vuex";

export default {
  name: "TerminosYCondiciones",
  components: {
    FlechaAtras,
    CardDetalleConPrecioFinal,
    Boton,
  },
  computed: {
    ...mapState(["icons"]),
  },
  methods: {},
  title() {
    return `Términos y condiciones - HummingBird`;
  },
};
</script>

<style lang="scss" scoped>
#Terminos,
.terminos {
  background: $main-white;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 95px;
  &__terminosYCondiciones {
    p,
    ul,
    li {
      font-size: 12px;
    }
  }
  &__btnBottom {
    position: fixed;
    bottom: 0;
  }
}
.titulo {
  text-decoration: underline;
  font-style: italic;
  padding-left: 25px;
}
ul {
  list-style: initial;
  ul {
    list-style: circle;
  }
}

.boldItalic {
  font-style: italic;
  font-weight: bold;
}

h3 {
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 16px;
}

p {
  padding-bottom: 16px;
}
a {
  color: $main-purple !important;
}

@media (min-width: 960px) {
  #Terminos,
  .terminos {
    background-color: $gray-background;
    padding-top: 100px;
    &__terminosYCondiciones {
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
      border-radius: 14px;
      background: $main-white;
      p,
      ul,
      li {
        font-size: 16px;
      }
    }
  }
}
</style>