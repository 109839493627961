<template>
  <div class="cambiarPasajeros">
    <div
      v-if="edit"
      class="cambiarPasajeros__edit d-flex flex-nowrap py-4 align-center justify-space-around"
    >
      <h2 class="text-center">
        {{
          vuelosElegidos.info.pasajeros > 1
            ? vuelosElegidos.info.pasajeros + " pasajeros"
            : vuelosElegidos.info.pasajeros + " pasajero"
        }}
      </h2>
      <div>
        <v-btn
          x-small
          dark
          fab
          :color="colors.mainPurple"
          :disabled="vuelosElegidos.info.pasajeros <= 1"
          @click="disminuirPasajeros"
          class="mr-1"
          >-</v-btn
        >
        <v-btn
          x-small
          dark
          fab
          :color="colors.mainPurple"
          :disabled="vuelosElegidos.info.pasajeros >= pasajerosPermitidos"
          @click="aumentarPasajeros"
          class="ml-1"
          >+</v-btn
        >
      </div>
    </div>

    <div
      v-else
      class="cambiarPasajeros__show py-4 d-flex justify-center align-center"
    >
      <h3 class="pr-4">
        {{
          vuelosElegidos.info.pasajeros > 1
            ? `${vuelosElegidos.info.pasajeros} pasajeros`
            : `${vuelosElegidos.info.pasajeros} pasajero`
        }}
      </h3>
      <p @click="editarPasajeros" class="pl-4">Editar datos</p>
    </div>
  </div>
</template>

<script>
import Boton from "@/components/Botones/Boton";
import { mapMutations, mapState } from "vuex";
export default {
  name: "CambiarPasajeros",
  components: {
    Boton,
  },
  data() {
    return {
      edit: false,
      pasajeros: null,
    };
  },
  computed: {
    ...mapState(["colors"]),
    ...mapState("Ventas", ["vuelosElegidos"]),
    pasajerosPermitidos() {
      const capacidadIda = this.vuelosElegidos.ida.data.capacidad;
      const ventasIda = this.vuelosElegidos.ida.data.ventas;
      const asientosDisponiblesIda = capacidadIda - ventasIda;
      let pasajerosPermitidos = asientosDisponiblesIda;

      if (this.vuelosElegidos.vuelta) {
        const capacidadVuelta = this.vuelosElegidos.vuelta.data.capacidad;
        const ventasVuelta = this.vuelosElegidos.vuelta.data.ventas;
        const asientosDisponiblesVuelta = capacidadVuelta - ventasVuelta;
        const menorDisponibilidad = Math.min(
          asientosDisponiblesIda,
          asientosDisponiblesVuelta
        );
        pasajerosPermitidos = menorDisponibilidad;
      }

      return pasajerosPermitidos;
    },
  },
  methods: {
    ...mapMutations("Ventas", ["EDITAR_PASAJEROS"]),
    editarPasajeros() {
      this.edit = true;
    },
    aumentarPasajeros() {
      if (this.vuelosElegidos.info.pasajeros < this.pasajerosPermitidos) {
        this.EDITAR_PASAJEROS(this.vuelosElegidos.info.pasajeros + 1);
      }
    },
    disminuirPasajeros() {
      if (this.vuelosElegidos.info.pasajeros > 1) {
        this.EDITAR_PASAJEROS(this.vuelosElegidos.info.pasajeros - 1);
      }
    },
  },
  updated() {
    this.pasajeros = this.vuelosElegidos.info.pasajeros;
  },
};
</script>

<style lang="scss" scoped>
.cambiarPasajeros {
  // background: $main-white;
  // box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
  // border-radius: 14px;
  &__show {
    p {
      color: $main-purple;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &__edit {
    width: 100%;
    &__btn {
      width: 10px !important;
      height: 10px !important;
    }
  }
}
</style>
