<template>
  <v-container class="cardDetalleConPrecio pb-10 pt-6">
    <div class="cardDetalleConPrecio__tipoViaje d-flex align-center pb-4">
      <img :src="icons.purple.plane" alt="" height="25" />
      <h3>{{ tipoViajeYVuelo }}</h3>
    </div>

    <div
      class="cardDetalleConPrecio__fechas d-flex pb-4"
      :class="{ 'justify-space-around': vuelosElegidos.vuelta }"
    >
      <div>
        <h3>{{ vuelosElegidos.vuelta ? "Vuelo de ida" : "Fecha de vuelo" }}</h3>
        <p>{{ vuelosElegidos.ida.data.fecha }}</p>
      </div>
      <v-divider v-if="vuelosElegidos.vuelta" vertical class="mx-2" />
      <div v-if="vuelosElegidos.vuelta">
        <h3>Vuelo de regreso</h3>
        <p>{{ vuelosElegidos.vuelta.data.fecha }}</p>
      </div>
    </div>

    <div class="cardDetalleConPrecio__pasajeros mb-4">
      <CambiarPasajeros />
    </div>

    <div class="cardDetalleConPrecio__equipaje d-flex align-center pb-4">
      <img :src="icons.purple.luggage" alt="" height="25" class="pr-5" />
      <h3>Equipaje de mano y de cabina</h3>
    </div>

    <div
      class="cardDetalleConPrecio__precio d-flex flex-column text-center pb-4"
    >
      <div class="d-flex align-center justify-center">
        <h2 class="pr-3">${{ precioEnMiles(precioTotal) }}</h2>
        <h5>Precio final</h5>
      </div>
      <p>
        <span v-if="preciosPorPersonaConFormato.vuelta">Ida: </span> ${{
          preciosPorPersonaConFormato.ida
        }}
        precio por pasajero
      </p>
      <p v-if="preciosPorPersonaConFormato.vuelta">
        Vuelta: ${{ preciosPorPersonaConFormato.vuelta }} precio por pasajero
      </p>
    </div>
    <router-link :to="linkTo">
      <Boton
        texto="Aceptar y continuar"
        width="100%"
        height="63px"
        borderRadius="50px"
      />
    </router-link>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Boton from "@/components/Botones/Boton";
import CambiarPasajeros from "@/components/CambiarPasajeros";
export default {
  name: "CardDetalleConPrecioFinal",
  components: {
    Boton,
    CambiarPasajeros,
  },

  computed: {
    ...mapState(["icons"]),
    ...mapState("Ventas", ["vuelosElegidos", "promoAplicada"]),
    precioTotal() {
      const precioIda = this.vuelosElegidos.ida.data.precio || 0;
      const precioVuelta = this.vuelosElegidos.vuelta
        ? this.vuelosElegidos.vuelta.data.precio
        : 0;
      const pasajeros = this.vuelosElegidos.info.pasajeros || 0;
      let total = 0;

      total = precioIda * pasajeros;

      this.vuelosElegidos.vuelta ? (total += precioVuelta * pasajeros) : total;

      return total;
    },
    precioEnMiles: () => (numero) => {
      if (numero === undefined) {
        numero = 0;
      }

      return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    tipoViajeYVuelo() {
      const tipoViaje = this.vuelosElegidos.info.tipoDeViaje.slice(0, -1);
      const tipoVuelo = this.vuelosElegidos.info.tipoDeVuelo;

      return tipoViaje === "Experiencia"
        ? "Experiencia"
        : `${tipoViaje} ${tipoVuelo}`;
    },
    preciosPorPersonaConFormato() {
      const precioIda = this.precioEnMiles(this.vuelosElegidos.ida.data.precio);
      const precioVuelta = this.vuelosElegidos.vuelta
        ? this.precioEnMiles(this.vuelosElegidos.vuelta.data.precio)
        : false;

      return {
        ida: precioIda,
        vuelta: precioVuelta,
      };
    },
    linkTo() {
      if (this.$route.name === "TerminosYCondiciones") {
        return { name: "Pago" };
      } else {
        return "/";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cardDetalleConPrecio {
  background: $main-white;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  color: #707070;
  h3 {
    font-size: 14px;
  }
  p {
    font-size: 12px;
  }

  &__precio {
    h2 {
      font-size: 25px;
    }
    p {
      font-size: 12px;
    }
  }
}
</style>
