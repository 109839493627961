<template>
  <section id="Terminos" class="terminos">
    <v-container class="mx-auto terminos__wrapper">
      <v-row no-gutters>
        <!-- <v-col cols="1" class="d-none d-md-block">
          <FlechaAtras />
        </v-col> -->
        <v-col class="terminos__terminosYCondiciones">
          <v-container class="pa-10">
            <h1 class="mb-6 md-md-3">CONDICIONES GENERALES DE VENTA</h1>
            <div class="et_pb_text_inner">
              <p class="font-italic"><em>ULTIMA ACTUALIZACION: A 28 DE FEBRERO DE 2021</em></p>
              <p class="titulo">ANTECEDENTES Y DECLARACIONES</p>
              <p>
                HUMMING S.p.A. es una sociedadconstituida y existente en
                conformidad a las leyes de la República de Chile. Su Rol Único
                Tributario (R.U.T.) es 77.477.578-1.
              </p>
              <p class="titulo">PREÁMBULO</p>
              <p>
                <a href="http://www.hummingbird.cl/">www.hummingbird.cl</a>
                (en adelante el “cyber usuario”), denominadas colectivamente
                “las partes”. Las partes convienen que sus relaciones serán
                regidas exclusivamente por estas Condiciones Generales de Venta,
                las cuales son accesibles en la página web
                <a href="http://www.hummingbird.cl/">www.hummingbird.cl</a>,
                debiendo ser, en todo caso, previamente aceptadas por el cyber
                usuario. En caso de modificación de las mismas por parte de
                <span>HummingBird</span>, las condiciones a aplicar a cada cyber
                usuario siempre serán las que aceptó en el momento de efectuar
                su compraventa. El cyber usuario, con carácter previo a la
                contratación y en todo caso en el proceso de compra, podrá
                acceder, archivar e imprimir estas condiciones generales de
                contratación para su consulta
              </p>
              <p class="titulo">ARTÍCULO 1</p>
              <p class="titulo">PROCEDIMIENTO DE COMPRAVENTA</p>
              <p>
                El cyber usuario declara tener a lo menos dieciocho años y la
                capacidad jurídica necesaria para efectuar una compraventa en la
                página web
                <a href="http://www.hummingbird.cl/">www.hummingbird.cl</a> El
                cyber usuario se obliga, en todo momento, a respetar las
                presentes Condiciones Generales de Venta.
              </p>
              <p>
                <a href="http://www.hummingbird.cl/">www.hummingbird.cl</a> se
                efectúa siguiendo los pasos que se describen a continuación:
              </p>
              <p>
                <span class="font-weight-bold">1- Accede</span> a
                <a href="http://www.hummingbird.cl/">www.hummingbird.cl</a> y
                conéctate a la tienda Cuando hayas encontrado el producto que
                deseas, haz clic sobre el botón “Añadir al carrito”
              </p>
              <p class="titulo">2- Mi carrito</p>
              <p>
                Podrás visualizar los productos agregados a tu carrito haciendo
                clic en el icono del carrito en el menu principal. En tu
                carrito, puedes modificar tus cantidades o suprimir los
                artículos que no desees. Si deseas finalizar la compra, haz clic
                sobre el botón “Terminar de comprar”
              </p>
              <p class="titulo">3- Identifícate</p>
              <p>
                Si eres un usuario registrado, introduce tu e-mail y contraseña
                y pincha en “Me identifico”.
              </p>
              <p>
                Si eres un cliente nuevo, introduce en el espacio para clientes
                nuevos, tu e-mail dos veces, y pincha en “Crear mi cuenta”. A
                continuación, serás redirigido a un formulario de registro, que
                deberás rellenar y validar para quedar registrado.
              </p>
              <p class="titulo">4- Valida el carrito y elige el pago</p>
              <p>Comprueba todos los datos de tu carrito :</p>
              <p>
                i) Dirección de facturación: si deseas modificarla pincha en
                “Modificar mis datos”.
              </p>
              <p>
                ii) Dirección de envío: si deseas modificarla pincha en
                “Modificar mis datos”.
              </p>
              <p>iii) Cesta de tu pedido.</p>
              <p>iv) Formas de pago: Tarjeta de crédito/débito</p>
              <p>
                Pincha en “Admito haber leído y acepto las Condiciones Generales
                de la Venta”. Si estás conforme con toda la información de tu
                pedido, pincha en “Confirmar pago”.
              </p>
              <p class="titulo">6- Pago</p>
              <p>
                Serás redirigido a través de una conexión segura.Introduce tus
                datos bancarios y valida.
              </p>
              <p>
                Recibirás simultáneamente una respuesta por parte del banco,
                indicándote si tu petición de autenticación es aceptada o
                denegada.
              </p>
              <p>
                Pincha en “volver a la tienda” para cerrar la conexión
                encryptada y volver a la web de
                <a href="http://www.hummingbird.cl/">www.hummingbird.cl</a>
              </p>
              <p>
                Recibirás un primer e-mail, que te informará de que tu petición
                ha sido tenida en cuenta una vez finalizado el proceso compra.
              </p>
              <p>Recibirás un nuevo e-mail con los detalles de tu reserva.</p>
              <p class="titulo">
                8- Política de cambios y devoluciones HUMMINGBIRD
              </p>
              <p>
                Sin perjuicio alguno de la garantía legal de los servicios, se
                establece una política de cambios y devoluciones en los
                siguientes términos:
              </p>
              <p>
                Para realizar un cambio y/o devolución puedes enviarnos un
                correo a contacto@hummingbird.cl. El plazo para el cambio o
                devolución de una reserva es de siete (7) días.
              </p>
              <p>
                La devolución se efectuará en el mismo medio de pago utilizado
                para la compra (tarjeta bancaria…) o realizando transferencia
                bancaria.
              </p>
              <p class="titulo">ARTÍCULO 2 : LOS PRECIOS</p>
              <p>
                Los precios fijados en la página web<a
                  href="http://www.hummingbird.cl/"
                  >www.hummingbird.cl</a
                >
                se indican en pesos chilenos ($ CLP) incluyendo el IVA en cada
                uno de ellos. El precio de venta al público del artículo se
                indicará siempre en el momento en que el cyber usuario haya
                completado la cesta de compra, y se informará al cliente del
                precio final de su compra antes de realizar la validación del
                pago. Los precios indicados para cada artículo son vinculantes
                para la tienda en la que se efectúa la compra por parte del
                cyber usuario.
              </p>
              <p>
                Se informará al cliente del precio final de su compra antes de
                realizar la validación del pago.
              </p>
              <p class="titulo">ARTÍCULO 3: DISPONIBILIDAD</p>
              <p>
                HummingBird, se compromete, a partir de la solicitud de
                compraventa efectuada por el cyber usuario, a tomar en
                consideración su petición y procederá, en su caso, a
                validar/confirmar total, parcialmente o nula la compraventa, con
                el fin de que éste pueda aceptar la compraventa y formalizar así
                la misma.
              </p>
              <p>
                HummingBird, se compromete a notificar el estado de su pedido al
                cyber usuario, a través de e-mail, llamada telefónica (Esta
                última, en caso de que HummingBird, no haya podido confirmar la
                disponibilidad total del vuelo o servicio) y/o actualización del
                pedido en el espacio personal del cliente al que éste puede
                acceder pinchando en “Mi cuenta”.
              </p>
              <p>
                En el caso de que la confirmación de compraventa se produzca
                sobre la totalidad de los vuelos/servicios seleccionados,
                HummingBird contactará con el cyber usuario a través de email,
                informándole de los servicios adquiridos. HummingBird archivará
                el documento electrónico en el que se formalice el contrato de
                compraventa, y éste será accesible por el cliente a través del
                enlace “Mi cuenta”. El contrato de compraventa será formalizado
                en idioma español.
              </p>
              <p>
                En el caso de que la confirmación de compraventa se produzca de
                forma parcial debido a la falta de disponibilidad de algunos
                servicios seleccionados, HummingBird contactará con el cliente
                informándole de los servicios finalmente afectados por la
                compraventa vía e-mail, telefónica y/o a través de la
                actualización del pedido en el espacio personal del cliente, al
                que éste accede pinchando en “Mi cuenta”. HummingBird archivará
                el documento electrónico en el que se formalice el contrato de
                compraventa, y éste será accesible por el cliente a través del
                enlace “Mi cuenta”. El contrato de compraventa será formalizado
                en español.
              </p>
              <p>
                En el caso de que no se produzca la confirmación de la
                compraventa por la falta de disponibilidad de la totalidad de
                los servicios seleccionados, HummingBird, informará al cyber
                usuario de que su compraventa no puede ser realizada, a través
                de vía telefónica, vía correo electrónico y/o a través de la
                actualización del pedido en el espacio personal del cliente, al
                que éste accede pinchando en “Mi cuenta”.
              </p>
              <p>
                En el caso de que no sea posible ejecutar el contrato por falta
                de disponibilidad de los servicios seleccionados, HummingBird
                devolverá sin ninguna demora indebida las sumas que haya abonado
                el cyber usuario en virtud del mismo.
              </p>
              <p class="titulo">ARTÍCULO 4 : DERECHO DE DESISTIMIENTO</p>
              <p>
                El comprador podrá renunciar a la compraventa. En tal sentido,
                el comprador podrá desistirse libremente del contrato de
                compraventa dentro del plazo de siete (7) días desde que éste se
                haya formalizado y en la medida que el servicio no haya sido
                utilizado en el intertanto.
              </p>
              <p>
                El derecho de desistimiento se realizará a través de los
                siguientes procedimientos:
              </p>
              <p>
                – Mediante el envío de un e-mail a través del apartado
                contáctanos situado en la web indicando la siguiente
                información: datos personales y domicilio del cyber usuario;
                número de pedido y fecha de adquisición del pedido;
              </p>
              <p>
                – A través del espacio personal de “Mi Cuenta”, a la que podrás
                acceder desde el siguiente enlace :
              </p>
              <p>– Mediante el Servicio de Atención Cliente de HummingBird.</p>
              <p>
                Una vez recibida la declaración de desistimiento, HummingBird
                confirmará su recepción al cyber usuario mediante el envío de un
                correo electrónico.
              </p>
              <p>
                En el caso de que el cyber usuario ejerza su derecho de
                devolución o desistimiento de la compraventa realizada,
                HummingBird reembolsará el importe previamente cargado al cyber
                usuario, incluidos los costes de entrega de la primera
                devolución o cambio, en un plazo no superior a 15 (quince) días
                corridos a contar desde la fecha en que haya sido informado de
                la decisión de desistimiento. La devolución de los pedidos se
                realizarán mediante transferencia bancaria.
              </p>
              <p class="titulo">ARTÍCULO 5 : RESPONSABILIDADES</p>
              <p>
                La disponibilidad de los servicios en el sitio web y durante el
                proceso de compra en la tienda online, es mencionada a título
                indicativo y no revestirá por lo tanto carácter contractual ni
                vinculante para HummingBird , ya que se pueden estar produciendo
                reservas en el mismo momento y resultar, en definitiva, el
                servicio indisponible. No serán responsabilidad de HummingBird ,
                aquellos daños que pudieran ocasionar al cyber usuario la
                utilización de la red internet (tales como virus, etc.) para
                acceder al sitio web.
              </p>
              <p class="titulo">ARTÍCULO 6 : LICENCIA DE ACCESO AL SITIO</p>
              <p>
                HummingBird concede una licencia limitada al acceso y a la
                utilización del sitio web con la finalidad de conocer los
                servicios ofertados y realizar las adquisiciones deseadas. En
                ningún caso esto confiere el derecho para el cyber usuario de
                cargar o de modificar aquellos contenidos del sitio web sin la
                autorización expresa de HummingBird . Esta licencia no permite
                ninguna otra utilización, particularmente comercial, del sitio
                web o de su contenido (productos, descripciones, precios, datos,
                software, extractos sonoros, grafismo, imágenes, fotografías,
                etc.). La integridad de los elementos de este sitio web, sean de
                la naturaleza que sean, son propiedad exclusiva de HUMMING
                S.p.A.
              </p>
              <p class="titulo">
                ARTÍCULO 7 : PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL
              </p>
              <p>
                Para el procedimiento de alta en los servicios gratuitos
                ofrecidos por HummingBird , que requieran de suscripción previa
                o registro previo, es necesario recopilar datos personales del
                usuario.
              </p>
              <p>
                La política de privacidad de HummingBird se encuentra expresada
                en el Aviso de Privacidad disponible en el sitio web.
              </p>
              <p>
                Para el ejercicio de sus derechos, el usuario deberá contactar
                HummingBird a través del apartado Contáctanos situado en la
                parte superior derecha de la web www.hummingbird.cl
              </p>
              <p class="titulo">ARTÍCULO 8 : INTEGRALIDAD</p>
              <p>
                Si una o varias de las disposiciones de las presentes
                Condiciones Generales de Venta se declaran nulas en virtud de la
                aplicación de una ley, reglamento o tras una decisión judicial o
                administrativa firme o ejecutoriada, las restantes disposiciones
                conservarán su validez y alcance.
              </p>
              <p>
                El hecho de que una de las partes no haya exigido la aplicación
                de una cláusula cualquiera de las presentes condiciones de
                manera permanente o temporal, no podrá considerarse en ningún
                caso como una renuncia a dicha cláusula.
              </p>
              <p class="titulo">ARTÍCULO 9 : PROMOCIONES</p>
              <p>
                Todas las promociones en la página web
                <a href="http://www.hummingbird.cl/">www.hummingbird.cl</a>
                permanecerán vigentes mientras exista disponibilidad de stock.
              </p>
              <p class="titulo">ARTÍCULO 10 : DERECHO APLICABLE</p>
              <p>
                Las presentes Condiciones Generales de Venta están sometidas a
                la legislación chilena. Cualquier litigio que pueda surgir entre
                las partes relativa a la interpretación o ejecución de las
                presentes condiciones serán competencia de los tribunales de la
                ciudad de Santiago de Chile.
              </p>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <!-- <router-link :to="{ name: 'Pago' }">
      <Boton
        texto="Aceptar y continuar"
        width="100%"
        height="92px"
        borderRadius="0"
        class="terminos__btnBottom d-md-none"
      />
    </router-link> -->
  </section>
</template>

<script>
import FlechaAtras from "@/components/FlechaAtras";
import CardDetalleConPrecioFinal from "@/components/Cards/CardDetalleConPrecioFinal";
import Boton from "@/components/Botones/Boton";
import { mapState } from "vuex";

export default {
  name: "TerminosYCondiciones",
  components: {
    FlechaAtras,
    CardDetalleConPrecioFinal,
    Boton,
  },
  computed: {
    ...mapState(["icons"]),
  },
  methods: {},
  title() {
    return `Términos y condiciones - HummingBird`;
  },
};
</script>

<style lang="scss" scoped>
#Terminos,
.terminos {
  background: $main-white;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 95px;
  &__terminosYCondiciones {
    p {
      font-size: 12px;
    }
  }
  &__btnBottom {
    position: fixed;
    bottom: 0;
  }
}

.titulo {
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 16px;
}

p {
  padding-bottom: 16px;
}
a {
  color: $main-purple !important;
}

@media (min-width: 960px) {
  #Terminos,
  .terminos {
    background-color: $gray-background;
    padding-top: 100px;
    &__terminosYCondiciones {
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
      border-radius: 14px;
      background: $main-white;
      p {
        font-size: 18px;
      }
    }
  }
}
</style>