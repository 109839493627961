<template>
  <img v-if="blanco" :src="icons.white.backArrow" alt="volver" @click="$router.go(-1)" class="backArrow" />
  <img v-else :src="icons.gray.backArrow" alt="volver" @click="$router.go(-1)" class="backArrow" />

</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "flechaAtras",  
  props: {
    blanco:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['icons'])
  },
};
</script>

<style lang="scss" scoped>
.backArrow{
  filter: drop-shadow(0 0 2 #00000015);
  &:hover{
    cursor: pointer;
  }
}</style>